import React from '../../node_modules/react/umd/react.development.js';
import { Helmet } from 'react-helmet';
import CookieBar from '../components/CookieBar/CookieBar';
import SubpageNavbar from '../components/SubpageNavbar/SubpageNavbar';
import Intro from '../components/Intro/Intro';
import Cookies from '../components/Cookies/Cookies';
import Footer from '../components/Footer/Footer';
import './index.css';
import { LocMeta, Loc } from '../components/frontlocdata';

const CookiesView = () => {
  return (
    <div>
        <Helmet>
          <title>{LocMeta["title"][Loc]}</title>
          <meta name="description" content={LocMeta["description"][Loc]} />
        </Helmet>
	    <CookieBar />
		  <SubpageNavbar />
	  	<Intro />
	  	<Cookies />
		<Footer /> 
    </div>
  );
};

export default CookiesView;